import { types, flow, getSnapshot, getParent } from 'mobx-state-tree';
import { ApiStore } from './ApiStore';
import { SalesPerformance, NullSalesPerformance } from './SalesPerformance';

const _user = types.model({
    userId: types.string,
    firstName: types.string,
    lastName: types.string,
    email: types.string,
    active: types.optional(types.boolean, true),
    userType: types.enumeration(['Company Admin', 'Executive', 'Sales Manager', 'Sales Member', 'admin']),
    salesPerformance: types.optional(SalesPerformance, getSnapshot(NullSalesPerformance)),
    stripeCustomerId: types.maybe(types.string),
    subscriptionId: types.maybe(types.string),
    reportsToId: types.maybe(types.string),
    disabled: types.maybe(types.boolean),
    mfaEnabled: types.maybe(types.boolean),
    companyIds: types.optional(types.array(types.string), []),
    isReadOnly: types.optional(types.boolean, false)
}).views(self => ({
    get isReadOnlyExecutive() {
        return self.userType === 'Executive' && self.isReadOnly;
    },
    get isExecutive() {
        return self.userType === 'Company Admin' || self.userType === 'Executive' || self.userType === 'admin';
    },
    get isCompanyAdmin() {
        return self.userType === 'Company Admin';
    },
    get isAdmin() {
        return self.userType === 'admin';
    },
    get canChangeCreditCard() {
        return !!self.stripeCustomerId;
    },
    get isSalesMember() {
        return self.userType === 'Sales Member';
    },
    get fullName() {
        return `${self.firstName} ${self.lastName}`;
    },
    get hasMoreThanOneCompany() {
        return self.companyIds.length > 1;
    }
}));

export const User = types.compose('User', _user, ApiStore).actions(self => ({
    updateFromSnapshot: flow(function* updateFromSnapshot(snapshot) {
        const patch = {
            firstName: snapshot.firstName,
            lastName: snapshot.lastName,
            userType: snapshot.userType,
            reportsToId: snapshot.reportsToId,
            active: snapshot.active,
            mfaEnabled: snapshot.mfaEnabled,
            companyIds: snapshot.companyIds,
            isReadOnly: snapshot.isReadOnly
        };

        yield self.patchApi(`/users/${self.userId ?? snapshot.userId}`, patch, self);
    }),
    createFromSnapshot: flow(function* createFromSnapshot(snapshot) {
        snapshot.active = true;
        yield self.postApi('/users', snapshot, self);
    }),
    addUserToCompany: flow(function* addUserToCompany(companyId) {
        self.companyIds.push(companyId);

        yield self.updateFromSnapshot(self);
    }),
    removeUserFromCompany: flow(function* removeUserFromCompany(companyId) {
        self.companyIds = self.companyIds.filter(id => id !== companyId);

        yield self.updateFromSnapshot(self);
    }),
    delete: flow(function* deleteUser() {
        yield self.deleteApi(`/users/${self.userId}`);
    }),
}));

export const NullUser = User.create(
    {
        userId: '',
        firstName: '',
        lastName: '',
        companyId: '',
        email: '',
        active: true,
        userType: 'Company Admin'
    }
);
