import { types, flow, clone } from 'mobx-state-tree';
import { Assessment, NullAssessment } from './Assessment';
import { ApiStore } from './ApiStore';


export const _assessmentsStore = types.model({
    assessments: types.optional(types.array(Assessment), [])
}).views(self => ({
    get openAssessments() {
        return self.assessments.filter(assessment => !assessment.completedDateTime).sort((a, b) => new Date(b.completedDateTime) - new Date(a.completedDateTime));
    },
    get closedAssessments() {
        return self.assessments.filter(assessment => assessment.completedDateTime).sort((a, b) => new Date(b.completedDateTime) - new Date(a.completedDateTime));
    },
    openAssessmentsForUser(userId) {
        return self.openAssessments
            .filter(assessment => assessment.salesperson.userId === userId);
    },
    closedAssessmentsForUser(userId) {
        return self.closedAssessments
            .filter(assessment => assessment.salesperson.userId === userId);
    },
    assessmentForId(assessmentId) {
        return self.assessments.find(assessment => assessment.id === assessmentId).sort((a, b) => new Date(b.completedDateTime) - new Date(a.completedDateTime));
    },
    get assessmentsGroupedById() {
        return self.closedAssessments
            .slice()
            .sort((a, b) => new Date(b.completedDateTime) - new Date(a.completedDateTime))
            .reduce((acc, session) => {
                if (!acc[session.salesperson.userId]) {
                    acc[session.salesperson.userId] = [];
                }
                acc[session.salesperson.userId].push(session);
                return acc;
            }, {});
    },
    get usersWithOpenAssessments() {
        const result = [];
        const map = new Map();
        for (let assessment of self.openAssessments) {
            if (!map.has(assessment.salesperson.userId)) {
                result.push(assessment.salesperson);
                map.set(assessment.salesperson.userId, true);
            }
        }
        return result;
    },
    get usersWithClosedAssessments() {
        const result = [];
        const map = new Map();
        for (let assessment of self.closedAssessments) {
            if (!map.has(assessment.salesperson.userId)) {
                result.push(assessment.salesperson);
                map.set(assessment.salesperson.userId, true);
            }
        }
        return result;
    },
    userClosedAssessmentCount(userId, subject, secondarySubject) {
        let count = 0;

        for (let assessment of self.closedAssessmentsForUser(userId)) {
            if (assessment.subject === subject && assessment.secondarySubject === secondarySubject) {
                count++;
            }
        }

        return count;
    },
    assessmentHistoryForUser(userId, subject, secondarySubject) {
        let assessmentHistory = self.closedAssessmentsForUser(userId);

        if (subject) {
            assessmentHistory = assessmentHistory.filter(assessment => assessment.subject === subject && assessment.secondarySubject === secondarySubject);
        }

        assessmentHistory = assessmentHistory.sort((d1, d2) => { return new Date(d2.completedDateTime) - new Date(d1.completedDateTime); });

        return { lastAssessment: assessmentHistory[0], assessments: assessmentHistory, count: assessmentHistory.length };
    },
    assessmentCountForSessionType(userId, sessionType) {
        if (!userId || !sessionType) {
            return '';
        }
        let assessmentHistory = self.closedAssessmentsForUser(userId);

        return assessmentHistory.filter(assessment => assessment.sessionType === sessionType).length.toString();
    },
    nextAssessmentForUser(userId, subject, secondarySubject) {
        return self.openAssessmentsForUser(userId)
            .filter(assessment => assessment.subject === subject && assessment.secondarySubject === secondarySubject)
            .sort((d1, d2) => new Date(d1) - new Date(d2))[0];
    }
}));

export const AssessmentsStore = types
    .compose('AssessmentsStore', _assessmentsStore, ApiStore)
    .actions(self => ({
        loadCompanyAssessments: flow(function* loadCompanyAssessments(companyId) {
            let url = `/companies/${companyId}/assessments`;

            yield self.getApi(url, self.assessments);
        }),
        searchForAssessmentByVideoName: flow(function* searchForAssessmentByVideoName(companyId, videoName) {
            let url = `/companies/${companyId}/assessments?videoName=${encodeURIComponent(videoName)}`;

            const assessments = yield self.getApi(url);
            console.log('Assessments', assessments);

            return assessments;
        }),
        deleteAssessment: flow(function* deleteAssessment(assessmentId) {
            const assessment = self.assessments.find(assessment => assessment.id === assessmentId);
            const companyId = assessment.companyId;
            yield self.deleteApi(`/companies/${companyId}/assessments/${assessmentId}`, assessment);
        }),
        scheduleAssessment: flow(function* scheduleAssessment(assessmentSnapshot) {
            const companyId = assessmentSnapshot.companyId;

            const body = { ...assessmentSnapshot };
            delete body.id;
            const applyTo = clone(NullAssessment);
            yield self.postApi(`/companies/${companyId}/assessments`, body, applyTo);

            self.assessments.push(applyTo);
        }),
        updateAssessmentFromSnapshot: flow(function* updateAssessmentFromSnapshot(assessmentSnapshot) {
            const assessment = self.assessments.find(assessment => assessment.id === assessmentSnapshot.id);
            const companyId = assessmentSnapshot.companyId;
            delete assessmentSnapshot.id;
            delete assessmentSnapshot.companyId;

            yield self.patchApi(`/companies/${companyId}/assessments/${assessment.id}`, assessmentSnapshot, assessment);
        })
    }));