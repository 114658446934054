import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import MultiplyHeader from '../components/MultiplyHeader/MultiplyHeader';
import { componentContainerStyle, componentContentStyle } from '../constants';
import RecordPresentationSelection from '../components/RecordPresentation/RecordPresentationSelection';
import NewRecordPresentation from '../components/RecordPresentation/NewRecordPresentation';
const RecordPresentationPage = ({ usersStore, mode, videoPresentationStore, assessmentsStore, company, }) => {

    return (
        <div style={componentContainerStyle}>
            <MultiplyHeader text="Record Presentation" />
            <div style={componentContentStyle}>
                <Switch>
                    <Route
                        exact
                        path="/record-presentation"
                        render={() => (
                            <RecordPresentationSelection videoPresentationStore={videoPresentationStore} />
                        )}
                    />
                    {/* <Route
                        path="/record-presentation/start"
                        render={() => (
                            <RecordPresentation
                                videoPresentationStore={videoPresentationStore}
                                assessmentsStore={assessmentsStore}
                                usersStore={usersStore}
                                mode={mode}
                                company={company}
                            />
                        )}
                    /> */}
                    <Route
                        path="/record-presentation/casual_encounter"
                        render={() => (
                            <NewRecordPresentation
                                videoPresentationStore={videoPresentationStore}
                                usersStore={usersStore}
                                mode={mode}
                                company={company}
                                assessmentsStore={assessmentsStore}
                            />
                        )}
                    />
                    {/* Redirect any unknown paths under record-presentation to the selection page */}
                    <Redirect from="/record-presentation/*" to="/record-presentation" />
                </Switch>
            </div>
        </div>
    );
};

export default observer(RecordPresentationPage);
