import React, { Component } from 'react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Auth0Provider } from '@auth0/auth0-react';
import { UserContextProvider } from './UserContextProvider';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const domain = process.env.REACT_APP_AUTH_DOMAIN;
const clientId = process.env.REACT_APP_CLIENT_ID;
const audience = process.env.REACT_APP_AUDIENCE;

class AppWithAuth extends Component {
    render() {
        return (
            <Auth0Provider
                domain={domain}
                clientId={clientId}
                useRefreshTokens={true}
                cacheLocation={'localstorage'}
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    scope: 'openid profile email phone offline_access all',
                    audience: audience
                }}
            >
                <UserContextProvider>
                    <BrowserRouter>
                        <Elements stripe={stripePromise}>
                            <App />
                        </Elements>
                    </BrowserRouter>
                </UserContextProvider>
            </Auth0Provider>
        );
    }
}

export default AppWithAuth;
