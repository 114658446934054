export const CurrentUserInitializer = async (usersStore, adminStore, userId) => {
    try {
        await usersStore.loadCurrentUser(userId);

        if (usersStore.currentUser.isAdmin) {
            await adminStore.load();
        } else {
            await adminStore.loadEnterprise(userId);
        }

    } catch (error) {
        if (error.response?.status !== 404) {
            throw error;
        }
    }
};
