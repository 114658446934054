import React from 'react';
import { Icon } from 'semantic-ui-react';

const MurphyTag = () => {
    return (
        <div
            style={{
                color: '#0784a7',
                textAlign: 'right',
                fontFamily: '"Press Start 2P"',
                marginTop: -15,
                marginBottom: 10,
            }}
        >
            <Icon name="user secret" />
            <span style={{ fontSize: '8px' }}>Review Created by Murphy the MultiplyAI Agent</span>
        </div>
    );
};

export default MurphyTag;
