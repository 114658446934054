import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'semantic-ui-react';
import axios from 'axios';

const VideoPlayer = (props) => {
    const { videoKey } = props;
    const [videoUrl, setVideoUrl] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const videoRef = useRef();

    useEffect(() => {
        if (isVisible) {
            axios.get(`presigned-url?key=${videoKey}`).then((urlResponse) => {
                setVideoUrl(urlResponse.data.url);
            });
        }
    }, [isVisible, videoKey, setVideoUrl]);

    useEffect(() => {
        videoRef.current?.load();
    }, [videoUrl]);

    return (
        <div>
            <Button style={{ marginLeft: '8px', height: '32px', marginTop: '10px', marginBottom: '8px' }} onClick={() => setIsVisible(!isVisible)} content={isVisible ? 'Hide Video' : 'Show Video'} primary />
            {isVisible && videoUrl && (
                <video width="100%" controls ref={videoRef}>
                    <source src={videoUrl} type="video/mp4" />
                    No video found.
                </video>
            )}
            {isVisible && !videoUrl && <p>Loading video...</p>}
        </div>
    );
};

export default VideoPlayer;
