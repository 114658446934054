import React from 'react';
import { Icon } from 'semantic-ui-react';

const RecordingButton = ({ recording, toggleRecording }) => {
    const buttonText = recording ? 'Stop Recording' : 'Start Recording';
    const iconType = recording ? 'stop' : 'play';


    return (
        <div className='button-card'>
            <div className='video-cta'>
                <button
                    className='start-stop-button'
                    onClick={toggleRecording}
                >
                    <Icon name={`${iconType} circle outline`} />
                    {buttonText}
                </button>
            </div>
        </div>
    );
};

export default RecordingButton;