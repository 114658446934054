import { types } from 'mobx-state-tree';
import { ApiStore } from './ApiStore';

const videoPresentation = types.model('VideoPresentation', {
    uploadId: types.optional(types.string, ''),
    PartNumber: types.optional(types.number, 0),
    ETag: types.optional(types.string, ''),
    key: types.optional(types.string, '')
});

const videoChunck = types.model('VideoPresentation', {
    uploadUrl: types.optional(types.string, ''),
    key: types.optional(types.string, '')
});

export const Video = types.compose(videoPresentation, ApiStore);

export const VideoChunck = types.compose(videoChunck, ApiStore);

export const NullVideo = Video.create(
    {
        uploadId: ''
    }
);

export const NullVideoChunk = VideoChunck.create(
    {
        key: '',
        uploadUrl: ''
    }
);
