import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'semantic-ui-react';

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return <div style={{ textAlign: 'center' }}>
        <Button primary onClick={() => loginWithRedirect()}>Log In</Button>
        {/* <Button primary onClick={() => loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } })}>Sign Up</Button> */}
    </div>;
};

export default LoginButton;
