import React from 'react';
import { observer } from 'mobx-react';
import MyAssessmentsReportPage from './MyAssessmentsReportPage';
import NewAssessmentsReportPage from './NewAssessmentsReportPage';

const HomeLandingPage = (props) => {
    const { usersStore, assessmentsStore, companyStore } = props;
    const { currentUser } = usersStore;
    if (currentUser.userType === 'Sales Member') {
        return <MyAssessmentsReportPage
            usersStore={usersStore}
            assessmentsStore={assessmentsStore}
        />;
    }

    return <NewAssessmentsReportPage
        usersStore={usersStore}
        assessmentsStore={assessmentsStore}
        companyStore={companyStore}
    />;
};

export default observer(HomeLandingPage);