import { useState } from 'react';
import { Message, Icon } from 'semantic-ui-react';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
const MurphyStatusDisplay = ({ videoStatus, error, loadNewAssessment }) => {
    const [newAssessmentLoaded, setNewAssessmentLoaded] = useState(false);

    useEffect(() => {
        if (videoStatus === 'graded') {
            loadNewAssessment().then(() => {
                setNewAssessmentLoaded(true);
            });
        } else {
            setNewAssessmentLoaded(false);
        }
    }, [videoStatus, loadNewAssessment]);

    if (error) {
        return <ErrorDisplay message="Murphy encountered a problem grading your video.  Please contact support." />;
    }

    if (!videoStatus) {
        return null;
    }

    let message = '';

    if (videoStatus === 'uploading') {
        message = 'Uploading Video...';
    } else if (videoStatus === 'transcribing') {
        message = 'Your video is currently being transcribed.  This can take several minutes.';
    } else if (videoStatus === 'grading' || (videoStatus === 'graded' && !newAssessmentLoaded)) {
        message = 'Murphy is now grading your video.';
    } else if (videoStatus === 'graded' && newAssessmentLoaded) {
        message = <>
            Murphy is all finished.  You can view your report{' '}
            <Link to="/my-assessments-report" style={{ textDecoration: 'underline', fontWeight: 'bold', color: '#06718f' }}>here</Link>.
        </>;
    }

    return <Message color="blue" icon style={{ maxWidth: '800px', margin: '10px auto 10px auto' }}>
        {videoStatus !== 'graded' && <Icon name='circle notched' loading />}
        <Message.Content>
            <Message.Header>Murphy Progress</Message.Header>
            {message}
        </Message.Content>
    </Message>;
};

export default MurphyStatusDisplay;