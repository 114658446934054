import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const AuthContext = createContext();

export const useUserContext = () => useContext(AuthContext);

export const UserContextProvider = ({ children }) => {
    const { getAccessTokenSilently, isAuthenticated, user, loginWithRedirect, isLoading } = useAuth0();
    const [token, setToken] = useState(null);

    useEffect(() => {
        const getToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                setToken(accessToken);
            } catch (e) {
                if (e.error === 'missing_refresh_token' || e.error === 'login_required') {
                    console.log(e.error);
                    loginWithRedirect();
                }
                console.error(e);
            }
        };

        if (isAuthenticated && !isLoading) {
            getToken();
        }
    }, [isAuthenticated, getAccessTokenSilently, loginWithRedirect, isLoading]);

    const userId = user?.sub?.split('|')[1];

    useEffect(() => {
        axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

        axios.interceptors.request.use(async (config) => {

            const accessToken = await getAccessTokenSilently();
            if (!config.baseURL) {
                return config;
            }
            config.headers['Authorization'] = `Bearer ${accessToken}`;

            return config;
        });
    });

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, token, userId }}>
            {children}
        </AuthContext.Provider>
    );
};

export const withUserContext = (
    Component
) => {
    return function WithUser(props) {
        return (
            <AuthContext.Consumer>
                {(userContext) => (
                    <Component {...props} {...userContext} />
                )}
            </AuthContext.Consumer>
        );
    };
};
