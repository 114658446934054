import React, { Component } from 'react';
import { observer } from 'mobx-react';
import MultiplyHeader from '../components/MultiplyHeader/MultiplyHeader';
import { Dropdown, Button, Form, Icon } from 'semantic-ui-react';
import SalesPerformance from '../components/PerformanceReviews/SalesPerformance';
import { componentContainerStyle, componentContentStyle } from '../constants';
import MultipleAssessmentDisplay from '../components/PerformanceReviews/MultipleAssessmentDisplay';
import ValueReportEmailer from '../ValueReportEmailer';
import ErrorDisplay from '../components/ErrorDisplay/ErrorDisplay';
import { withRouter } from 'react-router-dom';

class ValueReportPage extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        const { match } = this.props;
        const { currentUser, isCurrentUserExecutive } = this.props.usersStore;

        const userType = currentUser.userType;
        const managerId = userType === 'Company Admin' || userType === 'Executive' || userType === 'admin' ? 0 : currentUser.userId;

        this.setState({
            salesPerformanceReadOnly: userType === 'Executive' || userType === 'Sales Member',
            hideUserDropdown: userType === 'Sales Member',
            hideManagerDropdown: !isCurrentUserExecutive && userType !== 'admin',
            managerId
        });

        if (userType === 'Sales Member') {
            this.setStateForUser(currentUser);
        }

        if (userType === 'Sales Manager' || userType === 'Company Admin' || userType === 'Executive' || userType === 'admin') {
            this.setStateForManager(managerId);
        }

        if (match.params.userId) {
            this.setStateForUserId(match.params.userId);
        }
    }

    companyUserOptions = () => {
        const { usersStore } = this.props;
        const { managerId } = this.state;

        let userOptions = usersStore.getDirectReports(managerId)
            .map(user => ({
                text: `${user.firstName} ${user.lastName}`,
                value: user.userId
            }));

        if (usersStore.currentUser.isExecutive && usersStore.companyAdmins.some(ce => ce.userId === managerId)) {
            const salesManagers = usersStore.salesManagers;

            userOptions = userOptions.filter(option => !salesManagers.some(manager => manager.userId === option.value));
        }
        userOptions.unshift({ text: 'Team Aggregation', value: 0 });
        return userOptions;
    };

    companyManagersOptions = () => {
        const { usersStore } = this.props;
        const { salesManagers, currentUser } = usersStore;
        let managerOptions = salesManagers
            .map(user => ({
                text: `${user.firstName} ${user.lastName}`,
                value: user.userId
            }));
        managerOptions.unshift({ text: 'Managers Aggregation', value: 0 });

        if (currentUser.isExecutive) {
            const { companyAdminsWithSalesMemberReports } = usersStore;

            const companyAdminsOptions = companyAdminsWithSalesMemberReports
                .map(user => ({
                    text: `${user.firstName} ${user.lastName}`,
                    value: user.userId
                }));
            managerOptions = managerOptions.concat(companyAdminsOptions);
        }

        return managerOptions;
    };

    onUserChange = (data) => {
        if (data.value === 0) {
            const { managerId } = this.state;
            this.setStateForManager(managerId);
        } else {
            this.setStateForUserId(data.value);
        }
    };

    onManagerChange = (data) => {
        const managerId = data.value;
        this.setStateForManager(managerId);
    };

    setStateForManager = (managerId) => {
        const { usersStore } = this.props;
        if (managerId !== 0) {
            const directReports = usersStore.getDirectReports(managerId);
            const salesPerformance = usersStore.getAggregateSalesPerformance(directReports);

            this.setState({ selectedUser: 0, managerId, salesPerformance: salesPerformance, assessmentHistory: null, hideUserDropdown: false });
        } else {
            const salesPerformance = usersStore.getAggregateSalesPerformance(usersStore.companyUsers);

            this.setState({ selectedUser: 0, managerId: 0, salesPerformance: salesPerformance, assessmentHistory: null, hideUserDropdown: true });
        }

    };

    setStateForUserId = (userId) => {
        const { assessmentUsers } = this.props.usersStore;

        const user = assessmentUsers.find(user => user.userId === userId);
        if (user) {
            this.setStateForManager(user.reportsToId);
            this.setStateForUser(user);
        }

    };

    setStateForUser(user) {
        const { assessmentsStore } = this.props;
        const assessmentHistory = assessmentsStore.assessmentHistoryForUser(user.userId);

        this.setState({ selectedUser: user, salesPerformance: user.salesPerformance, assessmentHistory });
    }

    onShareClick = () => {
        this.setState({ shareReport: true });
    };

    onSendClick = async () => {
        const { selectedUser, email, assessmentHistory } = this.state;
        try {
            this.setState({ error: '', emailSending: true });

            await ValueReportEmailer(selectedUser, email, assessmentHistory?.assessments.slice(0, 3));
            this.setState({ shareReport: false });

        } catch (error) {
            this.setState({ error: error.message });
        }

        this.setState({ emailSending: false, email: '' });
    };

    onEmailChange = event => {
        this.setState({ email: event.target.value });
    };

    getShareContainerStyle = () => {
        const { salesPerformanceReadOnly } = this.state;

        if (salesPerformanceReadOnly) {
            return {};
        } else {
            return { position: 'relative', top: -36, left: 120, marginRight: 120 };
        };
    };

    reportsDropDown = () => {
        const { selectedUser, hideUserDropdown } = this.state;
        return hideUserDropdown ||
            (<Dropdown
                style={{ marginLeft: 'auto' }}
                data-testid='user-select'
                button
                className='icon'
                labeled
                search
                icon="user"
                options={this.companyUserOptions()}
                value={selectedUser?.userId || 0}
                onChange={(e, d) => this.onUserChange(d)}
                placeholder="Select User" />);
    };

    managersDropDown = () => {
        const { managerId, hideManagerDropdown } = this.state;

        return hideManagerDropdown ||
            (<Dropdown
                data-testid='manager-select'
                button
                className='icon'
                labeled
                search
                icon="user"
                options={this.companyManagersOptions()}
                value={managerId}
                onChange={(e, d) => this.onManagerChange(d)}
                placeholder="Select Manager" />);
    };

    assessmentHistory = () => {
        const { assessmentHistory } = this.state;
        return assessmentHistory && (<MultipleAssessmentDisplay assessmentHistory={assessmentHistory} />);
    };

    shareReport = () => {
        const { shareReport, emailSending, email, selectedUser } = this.state;
        if (selectedUser === 0) {
            return (<></>);
        }

        return shareReport ?
            (<div>
                <Form loading={emailSending}>
                    <Form.Input placeholder="jane@yourcompany.com;joe@yourcompany.com" onChange={this.onEmailChange} />
                    <Form.Button data-testid="send" icon="send" onClick={this.onSendClick} primary disabled={!email}></Form.Button>
                </Form>
            </div>)
            : (<Button data-testid="share" icon onClick={this.onShareClick} labelPosition="right" primary>Share <Icon name="share" /></Button>);
    };

    render() {
        const { salesPerformance, salesPerformanceReadOnly, selectedUser, error } = this.state;

        const teamAggregationSelected = selectedUser === 0;
        return (
            <div style={componentContainerStyle}>
                <MultiplyHeader text="Value Report" />
                <div style={componentContentStyle}>
                    <div style={{ display: 'flex' }}>
                        {this.managersDropDown()}
                        {this.reportsDropDown()}
                    </div>
                    <div style={{ marginTop: 10 }} />
                    {this.assessmentHistory()}
                    {salesPerformance && <div>
                        <SalesPerformance salesPerformance={salesPerformance} user={selectedUser} readOnly={salesPerformanceReadOnly || teamAggregationSelected} />
                        <div style={this.getShareContainerStyle()}>
                            <ErrorDisplay message={error} />
                            {this.shareReport()}
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}

export default withRouter(observer(ValueReportPage));