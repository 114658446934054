import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from 'semantic-ui-react';
import RecordingNavigationBlocker from '../NavigationBlocker/RecordingNavigationBlocker';
import NavigationBlocker from '../NavigationBlocker/NavigationBlocker';
import posterImg from '../../video-camera.svg';
import DurationDisplay from './DurationDisplay';
import RecordingButton from './RecordingButton';
import { VideoPreview } from './VideoPreview';
import MurphyStatusDisplay from './MurphyStatusDisplay';

const NewRecordPresentation = ({ videoPresentationStore, usersStore, company, assessmentsStore }) => {
    const [recording, setRecording] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);
    const [numberOfTries, setNumberOfTries] = useState(1);
    const [recordingType] = useState('casual_encounter');
    const [chunksBuffer, setChunksBuffer] = useState([]);
    const [mediaStream, setMediaStream] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [videoStatus, setVideoStatus] = useState('');
    const [blobCount, setBlobCount] = useState(0);
    const videoRef = React.createRef();
    const pollingRef = React.useRef(null);
    const timeoutRef = React.useRef(null);
    const [murphyErrorOccurred, setMurphyErrorOccurred] = useState(false);

    const toggleRecording = async () => {
        if (recording) {
            await stopRecording();
        } else {
            await startRecording();
        }
        setRecording(!recording);
    };

    const getTimestamp = () => {
        const date = new Date();
        const pad = (num) => num.toString().padStart(2, '0');

        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1);
        const day = pad(date.getDate());
        const hour = pad(date.getHours());
        const min = pad(date.getMinutes());
        const sec = pad(date.getSeconds());

        return `${year}${month}${day}${hour}${min}${sec}`;
    };

    const createBlob = useCallback((blob, isFinal = false) => {
        setBlobCount((prevBlobCount) => {
            const newBlobCount = prevBlobCount + 1;
            const data = {
                userId: usersStore.currentUser.userId,
                ContentType: 'video/mp4',
                partNumber: newBlobCount,
                companyId: company.id
            };

            videoPresentationStore.uploadChunk(data, blob, isFinal);
            return newBlobCount;
        });
    }, [company.id, usersStore.currentUser.userId, videoPresentationStore]);

    useEffect(() => {
        const bufferSize = chunksBuffer.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.size;
        }, 0);

        if (bufferSize >= 5 * 1024 * 1024) {
            const blobChunks = new Blob(chunksBuffer, { type: 'video/mp4' });
            setChunksBuffer([]);
            createBlob(blobChunks);
        }
    }, [chunksBuffer, chunksBuffer.length, createBlob]);

    const handleDataAvailable = (event) => {
        setChunksBuffer((prevChunks) => {
            return [...prevChunks, event.data];
        });
    };

    const startRecording = async () => {
        setMurphyErrorOccurred(false);
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true
        });
        videoRef.current.srcObject = stream;
        videoRef.current.play();

        const mediaRecorder = new MediaRecorder(stream);

        setRecording(true);
        setChunksBuffer([]);
        setMediaStream(stream);
        setMediaRecorder(mediaRecorder);

        const data = {
            userId: usersStore.currentUser.userId,
            ContentType: 'video/mp4',
            key: `recording_${getTimestamp()}_${recordingType}`,
            numberOfTries,
            companyId: company.id
        };

        await videoPresentationStore.uploadInitialChunk(data);

        mediaRecorder.ondataavailable = (e) => handleDataAvailable(e);
        mediaRecorder.start(5000);
    };

    const stopRecording = async () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
        }

        if (mediaStream) {
            mediaStream.getTracks().forEach((track) => track.stop());
        }

        setRecording(false);
        setShowPrompt(true);
        setMediaRecorder(null);
        setMediaStream(null);
    };

    const onDialogConfirm = async () => {
        const blobChunks = new Blob(chunksBuffer, { type: 'video/mp4' });

        if (blobChunks.size) {
            await createBlob(blobChunks, true);
        } else {
            const data = {
                companyId: company.id,
                userId: usersStore.currentUser.userId,
                ContentType: 'video/mp4',
                partNumber: blobCount + 1,
            };;

            await videoPresentationStore.finalizeUpload(data);
        }
        startPolling(company.id, videoPresentationStore.videoData.key);
        setChunksBuffer([]);
        setShowPrompt(false);
        setVideoStatus('uploading');
    };

    const onDialogCancel = () => {
        setNumberOfTries(numberOfTries + 1);
        setShowPrompt(false);
        setChunksBuffer([]);
    };

    const fetchAssessment = async (companyId, key) => {
        const assessments = await assessmentsStore.searchForAssessmentByVideoName(companyId, key);

        if (assessments.length === 0) {
            setVideoStatus('transcribing');
        }

        const assessment = assessments[0];

        if (assessment) {
            if (!assessment.grade || assessment.grade === null || assessment.grade === '') {
                setVideoStatus('grading');
            } else {
                setVideoStatus('graded');
                stopPolling();
            }
        }
    };

    const startPolling = (companyId, key) => {
        setMurphyErrorOccurred(false);
        setVideoStatus('');
        if (pollingRef.current) {
            clearInterval(pollingRef.current);
        }

        if (timeoutRef.current) {
            clearInterval(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            stopPolling();
            setMurphyErrorOccurred(true);
        }, 5 * 60 * 1000);
        pollingRef.current = setInterval(() => fetchAssessment(companyId, key), 5000); // Start polling
    };

    const stopPolling = () => {
        if (pollingRef.current) {
            clearInterval(pollingRef.current);
            pollingRef.current = null;
        }

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    };

    useEffect(() => {
        return stopPolling;
    }, []);

    return (
        <div className='record-presentation'>
            {showPrompt && <RecordingNavigationBlocker onConfirm={onDialogConfirm} onCancel={onDialogCancel} />}
            {true && <MurphyStatusDisplay videoStatus={videoStatus} error={murphyErrorOccurred} loadNewAssessment={() => assessmentsStore.loadCompanyAssessments(company.id)} />}
            <NavigationBlocker when={recording} prompt="Are you sure you want to leave, your recording has not been submitted" />
            <Grid stackable columns={1} style={{ height: '100%' }}>
                <Grid.Row id="videoTag">
                    <Grid.Column style={{ textAlign: '-webkit-center', height: '100%' }}>
                        <div className='live-video-card'>
                            <div className='timmer component' style={{ padding: '7px' }}>
                                <DurationDisplay recording={recording} />
                            </div>
                            <div className='video-component'>
                                <div className='common-box left-comp'>Michelle</div>
                                <div className='common-box right-comp'>David</div>
                                <div className='common-box left-comp'>Rebecca</div>
                                <video className={`${recording ? 'video' : ''} casual-encounterVideo right-comp before-recording`} ref={videoRef} poster={posterImg} muted />
                            </div>
                            <RecordingButton recording={recording} toggleRecording={toggleRecording} />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div >
    );
};

export default NewRecordPresentation;