import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Modal, Button } from 'semantic-ui-react';

const NavigationBlocker = (props) => {
    const { when, prompt } = props;
    return <NavigationPrompt when={when}>
        {({ onConfirm, onCancel }) => (
            <Modal open={true}>
                <Modal.Content>
                    {prompt ? prompt : 'You have unsaved changes.  Are you sure you want to leave?'}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Yes"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={onConfirm}
                        primary
                    />
                    <Button negative onClick={onCancel}>
                        No
                    </Button>
                </Modal.Actions>
            </Modal>
        )}
    </NavigationPrompt>;
};

export default NavigationBlocker;