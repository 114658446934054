import casualEncounterIcon from '../../casual-encounter-icon.svg';
import salesPresentationIcon from '../../sales-presentation-icon.svg';
import phoneCallIcon from '../../phone-call-icon.svg';
import { Icon, Grid } from 'semantic-ui-react';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import ComingSoonBanner from '../ComingSoonBanner/ComingSoonBanner';

const RecordPresentationSelection = ({ videoPresentationStore }) => {
    const [permission, setPermission] = useState(false);
    const [error, setError] = useState('');
    const [numberOfVideoDevices, setNumberOfVideoDevices] = useState(0);
    const history = useHistory();
    useEffect(() => {
        navigator.permissions.query({ name: 'camera' }).then((cameraResult) => {
            navigator.permissions
                .query({ name: 'microphone' })
                .then((microphoneResult) => {
                    if (
                        cameraResult.state !== 'granted' ||
                        microphoneResult.state !== 'granted'
                    ) {
                        getCameraAndMicrophonePermission();
                    } else {
                        setPermission(true);
                        checkInputDevices();
                    }
                });
        });
    });

    const checkInputDevices = async () => {
        const audioDevices = [], videoDevices = [];
        await navigator.mediaDevices.enumerateDevices().then((devices) => {
            devices.forEach((device) => {
                if (device.kind === 'videoinput') {
                    videoDevices.push(device);
                }
                else if (device.kind === 'audioinput') {
                    audioDevices.push(device);
                }
            });
            setNumberOfVideoDevices(videoDevices.length);
            if (audioDevices.length < 1) {
                setError('Audio device is required to proceed with recording.');
                setPermission(false);
            }
        });
    };

    const stopMediaDevices = (streamData) => {
        const stream = streamData;
        try {
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }
        } catch (err) {
            setError('Error stopping media devices');
        }
    };

    const getCameraAndMicrophonePermission = async () => {
        if ('MediaRecorder' in window) {
            try {
                const streamData = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: true,
                });
                setPermission(true);
                stopMediaDevices(streamData);
            } catch (err) {
                let errorMessage;

                switch (err.message) {
                    case 'Permission denied':
                        errorMessage = 'To record, please grant permission to access your camera and microphone.';
                        break;

                    case 'Requested device not found':
                        errorMessage = 'Both video and audio inputs are required to proceed with recording.';
                        break;

                    default:
                        errorMessage = err.message;
                        break;
                }

                setError(errorMessage);
            }
        } else {
            setError('MediaRecoder API is not supported in this browser.');
        }
    };

    const onRecordingTypeClick = (recordingType) => {
        videoPresentationStore.setRecordingType(recordingType);
        history.push('/record-presentation/casual_encounter');
    };

    const renderCards = () => {
        const recordingTypes = [
            {
                type: 'Casual Encounter',
                icon: casualEncounterIcon,
                recordingKey: 'casual_encounter',
                disabled: false
            },
            {
                type: 'Sales Presentation',
                icon: salesPresentationIcon,
                recordingKey: 'sales_presentation',
                disabled: true
            },
            {
                type: 'Phone Call',
                icon: phoneCallIcon,
                recordingKey: 'phone_call',
                disabled: true
            }
        ];

        return (
            <div className='record-card'>
                <Grid container stackable columns="equal">
                    <Grid.Row>
                        {
                            recordingTypes.map(recordingType => (
                                <Grid.Column key={recordingType.recordingKey} className={`${recordingType.recordingKey.replace('_', '-')}`}>
                                    <div className='ui card' style={{ border: 'solid 2px #dddddd', padding: 20, borderRadius: 5, minWidth: 230 }}>
                                        {recordingType.disabled && <ComingSoonBanner />}
                                        <div><img src={recordingType.icon} className='recording-card-icon' alt={recordingType.type} /></div>
                                        <h2 style={{ textWrap: 'nowrap' }}>{recordingType.type}</h2>
                                        <button disabled={!permission || recordingType.disabled || numberOfVideoDevices < 1} className={`start-recording-button btn-${recordingType.recordingKey.split('_')[0]}`} onClick={() => onRecordingTypeClick(recordingType.recordingKey)}><Icon name='video' />{recordingType.disabled ? 'Coming Soon' : 'Start Recording'}</button>
                                        <span></span> {/* This is a hack to make the button have rounded corners */}
                                    </div>
                                </Grid.Column>
                            ))}
                    </Grid.Row>
                </Grid>
            </div>
        );
    };

    return <>
        <div className='record-presentation'>
            <div style={{ textAlign: 'center', width: '100%', fontWeight: 600, color: '#4c4d50' }}>Choose the type of presentation to get started. Make sure you are prepared and facing the camera.
                {error && <ErrorDisplay message={error} />}
            </div>
            {renderCards()}
        </div>
    </>;
};

export default observer(RecordPresentationSelection);