import React, { useEffect, useState } from 'react';

const DurationDisplay = ({ recording }) => {
    const [currentTime, setCurrentTime] = useState(new Date());
    const [recordingStartDateTime, setRecordingStartDateTime] = useState(null);

    useEffect(() => {
        if (recording) {
            const startDateTime = new Date();
            setRecordingStartDateTime(startDateTime);
            setCurrentTime(new Date());
            const intervalId = setInterval(() => {
                setCurrentTime(new Date());
            }, 1000);

            return () => clearInterval(intervalId);
        } else {
            setRecordingStartDateTime(null);
            setCurrentTime(null);
        }
    }, [recording, setRecordingStartDateTime]);

    if (!recording) {
        return '';
    }

    const diffInMs = Math.abs(currentTime - recordingStartDateTime);

    // Convert to hours, minutes, and seconds
    const hours = Math.floor(diffInMs / (1000 * 60 * 60));
    const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

    // Format as hh:mm:ss with leading zeros
    const formattedTime = [
        hours.toString().padStart(2, '0'),
        minutes.toString().padStart(2, '0'),
        seconds.toString().padStart(2, '0'),
    ].join(':');

    return <p>{formattedTime}</p>;
};

export default DurationDisplay;