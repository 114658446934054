import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

const RecordingNavigationBlocker = ({ onConfirm, onCancel }) => {
    return <>
        <Modal open={true}>
            <Modal.Content>
                Are you ready to submit recording
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Yes"
                    labelPosition='right'
                    icon='checkmark'
                    onClick={onConfirm}
                    primary
                />
                <Button negative onClick={onCancel}>
                    No
                </Button>
            </Modal.Actions>
        </Modal>
    </>;
};

export default RecordingNavigationBlocker;