import { clone, flow, getSnapshot, types, runInAction } from 'mobx-state-tree';
import { ApiStore } from './ApiStore';
import { NullVideo, NullVideoChunk, Video, VideoChunck } from './VideoPresentation';
import * as Sentry from '@sentry/react';

const videoPresentation = types.model('VideoPresentationStore', {
    videoData: types.optional(Video, getSnapshot(NullVideo)),
    videoEtagData: types.optional(types.array(types.frozen()), []),
    videoChunck: types.optional(VideoChunck, getSnapshot(NullVideoChunk)),
    loading: types.optional(types.boolean, false),
    error: types.optional(types.string, ''),
    recordingType: types.optional(types.string, ''),
    uploadProgress: types.optional(types.string, 'Video Uploading...')
});

export const VideoPresentationStore = types.compose(videoPresentation, ApiStore)
    .views(self => ({
        get getAppEtags() {
            return self.videoEtagData;
        }
    }
    ))
    .actions((self) => ({
        setRecordingType(recordingType) {
            self.recordingType = recordingType;
        },
        uploadInitialChunk: flow(function* uploadInitialChunk(data) {
            self.videoEtagData = [];
            const body = { userId: data.userId, ContentType: data.ContentType, numberOfTries: data.numberOfTries, companyId: data.companyId };
            self.loading = true;
            self.error = '';
            try {
                const res = clone(NullVideo);
                yield self.postApi(`/companies/${data.companyId}/initialize-upload/${data.key}`, body, res);
                self.videoData = res;
            } catch (error) {
                console.error('Error fetching data:', error);
                self.error = error.message;
            } finally {
                self.loading = false;
            }
        }),
        uploadChunk: flow(function* uploadChunk(data, blob, isFinal = false) {
            const body = { userId: data.userId, ContentType: data.ContentType, PartNumber: data.partNumber, uploadId: self.videoData.uploadId };
            self.loading = true;
            self.error = '';
            try {
                const res = clone(NullVideoChunk);
                const keyWithoutExtension = self.videoData.key.split('/')[1].split('.')[0];
                yield self.postApi(`/companies/${data.companyId}/initialize-upload/${keyWithoutExtension}`, body, res);
                self.videoChunck = res;
                if (res.uploadUrl) {
                    const xhr = new XMLHttpRequest();
                    xhr.open('PUT', self.videoChunck.uploadUrl);
                    xhr.onerror = (error) => {
                        throw error;
                    };
                    xhr.onreadystatechange = async function () {
                        if (xhr.readyState === XMLHttpRequest.DONE) {
                            if (xhr.status === 200) {

                                const ETag = xhr.getResponseHeader('ETag');
                                self.addVideoEtagData({ PartNumber: data.partNumber, ETag: ETag.replace(/"/g, '') });
                                if (isFinal) {
                                    await self.finalizeUpload(data);
                                }
                            } else {
                                console.error('API request failed with status:', xhr.status, xhr.statusText);
                            }
                        }
                    };
                    xhr.send(blob);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                self.error = error.message;
            } finally {
                self.loading = false;
            }
        }),
        addVideoEtagData(item) {
            self.videoEtagData.push(item);
        },
        finalizeUpload: flow(function* finalizeUpload(data) {
            const body = { uploadId: self.videoData.uploadId, key: self.videoChunck.key, parts: self.videoEtagData };
            self.loading = true;
            self.error = '';
            if (Array.isArray(self.videoEtagData) && self.videoEtagData.length > 0) {
                try {
                    yield self.postApi(`/companies/${data.companyId}/finalize-upload`, body);
                    self.setUploadProgress('Video upload complete. Sending to Murphy for Grading');
                } catch (error) {
                    Sentry.captureException(error);
                    console.error('Error fetching data:', error);
                    self.error = error.message;
                } finally {
                    self.loading = false;
                }
            }
        }),
        setUploadProgress(progress) {
            self.uploadProgress = progress;
        }
    }));

