import React, { useState } from 'react';
import { FormField, Form, Dropdown, Button, Icon, Message, Label, LabelDetail } from 'semantic-ui-react';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

const NewAssessmentDetails = ({ assessments }) => {
    const [selectedAssessmentId, setSelectedAssessmentId] = useState(assessments[0]?.id);
    const dateOptions = assessments.map((assessment, index) => ({
        key: index,
        text: new Date(assessment.completedDateTime).toLocaleDateString(),
        value: assessment.id,
    }));

    const selectedAssessment = assessments.find(a => a.id === selectedAssessmentId);

    if (!selectedAssessment) {
        return <div style={{ margin: 20, fontWeight: 'bold' }}>No assessments completed.</div>;
    }
    return (
        <>
            {selectedAssessment.aiGenerated && (
                <div
                    style={{
                        backgroundColor: 'rgb(7, 132, 167)',
                        padding: '16px',
                        textAlign: 'center',
                    }}
                >
                    <div style={{ color: 'white', margin: 0, fontFamily: '"Press Start 2P"', fontSize: '9px' }}>
                        <Icon name='user secret' size='large' /> Review created by Murphy the MultiplyAI agent
                    </div>
                </div>
            )}
            <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px', paddingBottom: '10px' }}>
                <div style={{ marginBottom: '20px' }}>
                    <Form>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FormField width={4} style={{ marginRight: '20px' }}>
                                <label>Date of Assessment:</label>
                                <Dropdown
                                    compact
                                    selection
                                    options={dateOptions}
                                    value={selectedAssessmentId}
                                    onChange={(_, { value }) => setSelectedAssessmentId(value)}
                                    style={{ marginTop: '5px' }}
                                />
                            </FormField>
                            <Button
                                data-testid="assessment-grade-1"
                                color={selectedAssessment.grade}
                                icon
                                size="massive"
                                circular
                                style={{ marginTop: 10 }}
                            />
                            <Label style={{ marginTop: 10 }}>Attempts:<LabelDetail>{selectedAssessment.numberOfTries}</LabelDetail></Label>
                            <VideoPlayer videoKey={selectedAssessment.videoName} />
                        </div>

                    </Form>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '48%' }}>
                        <h3 style={{ color: 'black' }}>Positives</h3>
                        {selectedAssessment.positivesView.map((positive, i) =>
                            <Message positive icon key={`positive_${i}`}>
                                <Icon name='check circle' size='tiny' />
                                {positive}
                            </Message>
                        )}
                    </div>
                    <div style={{ width: '48%' }}>
                        <h3 style={{ color: 'black' }}>Areas For Improvement</h3>
                        {selectedAssessment.areasForImprovementView.map((negative, i) =>
                            <Message negative icon key={`negative${i}`}>
                                <Icon name='exclamation circle' size='tiny' />
                                {negative}
                            </Message>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewAssessmentDetails;