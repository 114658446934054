import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Table, Modal, Button, Checkbox } from 'semantic-ui-react';

const AllUsersListModal = observer(({ usersStore, open, onClose, onAdd }) => {
    const [selectedUsers, setSelectedUsers] = useState([]);

    useEffect(() => {
        usersStore.getUsersForCompanies(usersStore.currentUser.companyIds);
    }, [usersStore]);

    const handleUserSelect = (user) => {
        setSelectedUsers(prevSelectedUsers =>
            prevSelectedUsers.includes(user)
                ? prevSelectedUsers.filter(id => id !== user)
                : [...prevSelectedUsers, user]
        );
    };

    const renderTableRows = () => {
        return usersStore.allUsers.map((user, i) => (
            <Table.Row key={i}>
                <Table.Cell textAlign='center'>
                    <Checkbox
                        checked={selectedUsers.includes(user)}
                        onChange={() => handleUserSelect(user)}
                    />
                </Table.Cell>
                <Table.Cell>{user.firstName}</Table.Cell>
                <Table.Cell>{user.lastName}</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
            </Table.Row>
        ));
    };

    const handleClose = () => {
        onClose();

    };

    const handleAdd = () => {
        onAdd(selectedUsers);
        setSelectedUsers([]);
    };


    return (
        <Modal open={open} onClose={handleClose} size={'small'}>
            <Modal.Header>Select Users</Modal.Header>
            <Modal.Content style={{ textAlign: 'center' }}>
                <Table celled fixed style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign='center' width={1}></Table.HeaderCell>
                            <Table.HeaderCell>First Name</Table.HeaderCell>
                            <Table.HeaderCell >Last Name</Table.HeaderCell>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {renderTableRows()}
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose} secondary>
                    Cancel
                </Button>
                <Button onClick={handleAdd} primary>
                    Add
                </Button>
            </Modal.Actions>
        </Modal>
    );
});

export default AllUsersListModal;