import React, { Component } from 'react';
import { Table, Button, Input, Dropdown, Form, Icon, Grid, Checkbox, Message, Radio, Modal } from 'semantic-ui-react';
import { getSnapshot } from 'mobx-state-tree';
import { observer } from 'mobx-react';
import { componentContainerStyle, componentContentStyle } from '../../constants';
import Loading from '../Loading/Loading';
import MultiplyHeader from '../MultiplyHeader/MultiplyHeader';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import AllUsersListModal from '../AllUsersListModal/AllUsersListModal';

const blankUser = {
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    userType: '',
    mfaEnabled: false,
};

class UserManagement extends Component {
    constructor(props) {
        super(props);

        blankUser.reportsToId = props.usersStore.companyAdmins[0]?.userId || props.usersStore.currentUser.userId;

        this.state = {
            editIndex: -1,
            editUser: {},
            newUser: { ...blankUser },
            showPassword: false,
            error: '',
            showDeactivateCompanyAdmin: false,
            showAllUsersModal: false,
            selectedUsers: [],
            showRemoveUserModal: false,
            showDeleteUserModal: false,
            userToRemove: null,
            userToDelete: null,
        };
    }

    onEditClick(i) {
        const { companyUsers } = this.props.usersStore;
        this.setState({ editIndex: i, editUser: getSnapshot(companyUsers[i]) });
    }

    onCancelClick(i) {
        this.setState({ editIndex: -1 });
    }

    onGridDropdownChange(data, i) {
        let user = { ...this.state.editUser };

        user[data.name] = data.value;

        this.setState({ editUser: user });
    }

    onCheckboxChanged(data, i) {
        let user = { ...this.state.editUser };

        user[data.name] = data.checked;

        this.setState({ editUser: user });
    }

    onGridTextChange(event) {
        let user = { ...this.state.editUser };

        user[event.target.name] = event.target.value;

        this.setState({ editUser: user });
    }

    onNewUserDropdownChange = data => {
        const { newUser } = this.state;

        newUser[data.name] = data.value;
        this.setState({ newUser });
    };

    onNewUserTextChange = (event) => {
        const { newUser } = this.state;

        newUser[event.target.name] = event.target.value;
        this.setState({ newUser });
    };

    onNewUserMfaChange = (_, data) => {
        const { newUser } = this.state;
        newUser.mfaEnabled = data.checked;
        this.setState({ newUser });
    };

    onNewUserReadOnlyChange = (_, data) => {
        const { newUser } = this.state;

        newUser.isReadOnly = data.checked;

        this.setState({ newUser });
    };

    onEditUserMfaEnabledChange = (_, data) => {
        let user = { ...this.state.editUser };

        user.mfaEnabled = data.checked;

        this.setState({ editUser: user });
    };

    onEditUserReadOnlyChange = (_, data) => {
        let user = { ...this.state.editUser };

        user.isReadOnly = data.checked;

        this.setState({ editUser: user });
    };

    async onSaveClick(i) {
        const userSnapshot = this.state.editUser;

        this.setState({ error: '' });

        if (userSnapshot.userType === 'Company Admin' && !userSnapshot.active) {
            this.setState({ showDeactivateCompanyAdmin: true });
        } else {
            const user = this.props.usersStore.companyUsers[i];
            try {
                await user.updateFromSnapshot(userSnapshot);

                this.setState({ editIndex: -1 });
            } catch (error) {
                this.setState({ error: error.message });
            }
        }
    }

    saveNewUser = async () => {
        const { usersStore, company } = this.props;
        const newUser = { ...this.state.newUser, companyIds: [company.id] };

        if (newUser.userType === 'Executive') {
            delete newUser.reportsToId;
        }

        this.setState({ error: '' });

        try {
            await usersStore.signUpUserFromSnapshot(newUser);
            // await company.chargeForUser();
            this.setState({ newUser: { ...blankUser } });
        } catch (error) {
            this.setState({ error: error.message });
        }
    };

    formatBoolean(value) {
        return value ? 'Yes' : 'No';
    }

    userTypeOptions = [
        {
            key: 'Company Admin',
            text: 'Company Admin',
            value: 'Company Admin'
        },
        {
            key: 'Executive',
            text: 'Executive',
            value: 'Executive'
        },
        {
            key: 'Sales Manager',
            text: 'Sales Manager',
            value: 'Sales Manager'
        },
        {
            key: 'Sales Member',
            text: 'Sales Member',
            value: 'Sales Member'
        }
    ];

    userFromId = (id) => {
        const { usersStore } = this.props;
        const user = usersStore.companyUsers.find(u => u.userId === id);
        return user ? `${user.firstName} ${user.lastName}` : '';
    };

    potentialManagers = (editUser) => {
        const { usersStore } = this.props;

        const salesManagers = usersStore.salesManagers.map(m => {
            return {
                key: m.userId,
                text: `${m.firstName} ${m.lastName}`,
                value: m.userId
            };
        });

        const companyAdmins = usersStore.companyAdmins.map(m => {
            return {
                key: m.userId,
                text: `${m.firstName} ${m.lastName}`,
                value: m.userId
            };
        });

        const executives = usersStore.executives.map(m => {
            return {
                key: m.userId,
                text: `${m.firstName} ${m.lastName}`,
                value: m.userId
            };
        });

        switch (editUser.userType) {
            case 'Executive':
            case 'Enterprise Executive':
            case 'Company Admin':
            case 'admin':
                return [];
            case 'Sales Manager':
                return [...companyAdmins, ...executives];
            default:
                return [...salesManagers, ...companyAdmins, ...executives];
        }
    };

    saveButtonEnabled = () => {
        const { newUser } = this.state;
        return newUser.firstName
            && newUser.lastName
            && newUser.email
            && newUser.userType
            && newUser.email?.trim() === newUser.confirmEmail?.trim();
    };

    togglePassword = () => {
        this.setState(prevState => ({ showPassword: !prevState.showPassword }));
    };

    isCurrentUser = (user) => {
        const { currentUser } = this.props.usersStore;

        return currentUser.userId === user.userId;
    };

    getRankForUser = (user) => {
        switch (user.userType) {
            case 'admin':
                return 5;
            case 'Company Admin':
                return 4;
            case 'Executive':
                return 3;
            case 'Sales Manager':
                return 2;
            default:
                return 0;
        }
    };

    hasHigherRank = (user) => {
        const { currentUser } = this.props.usersStore;
        return this.getRankForUser(currentUser) >= this.getRankForUser(user);
    };

    isCompanyAdmin = (user) => {
        return user.userType === 'Company Admin';
    };

    moreThanOneCompanyAdmin = () => {
        return this.props.usersStore.companyUsers.filter(user => user.isCompanyAdmin).lenght > 1;
    };

    isAdmin = (user) => {
        return user.isAdmin;
    };

    canChangeUserType = (user) => {
        return !this.isCompanyAdmin(user) && !this.isCurrentUser(user);
    };

    canChangeActiveStatus = (user) => {
        const { currentUser } = this.props.usersStore;

        return (!this.isCompanyAdmin(user) || currentUser.isAdmin) && !this.isCurrentUser(user);
    };

    canChangeReportsTo = (user) => {
        return (!this.isCompanyAdmin(user) || this.moreThanOneCompanyAdmin()) && !this.isCurrentUser(user);
    };

    deactivateCompanyAdmin = async (user) => {
        const { usersStore } = this.props;

        if (user.isCompanyAdmin) {
            return;
        }

        await usersStore.deactivateCompanyAdmin(user.companyId);
    };

    openAllUsersModal = () => {
        this.setState({ showAllUsersModal: true });
    };

    onAddFromUserModal = async (selectedUsers) => {
        const promises = [];
        for (const user of selectedUsers) {
            promises.push(user.addUserToCompany(this.props.company.id));
        }

        await Promise.all(promises);
        await this.props.usersStore.loadCompanyUsers(this.props.company.id);
        this.setState({ showAllUsersModal: false });
    };

    closeAllUsersModal = () => {
        this.setState({ showAllUsersModal: false });
    };

    openRemoveUserModal = (user) => {
        this.setState({ showRemoveUserModal: true, userToRemove: user });
    };

    closeRemoveUserModal = () => {
        this.setState({ showRemoveUserModal: false, userToRemove: null });
    };

    confirmRemoveUser = async () => {
        const { userToRemove } = this.state;
        this.closeRemoveUserModal();
        await this.removeFromCompany(userToRemove);
    };

    openDeleteUserModal = (user) => {
        this.setState({ showDeleteUserModal: true, userToDelete: user });
    };

    closeDeleteUserModal = () => {
        this.setState({ showDeleteUserModal: false, userToDelete: null });
    };

    confirmDeleteUser = async () => {
        const { userToDelete } = this.state;
        const u = this.props.usersStore.companyUsers.find(u => u.userId === userToDelete.userId);
        this.closeDeleteUserModal();
        try {
            this.setState({ error: '' });
            await u.delete();
            await this.props.usersStore.loadCompanyUsers(this.props.company.id);
        } catch (e) {
            this.setState({ error: e.message });
        }
    };

    renderNewUserForm() {
        const { newUser, showDeactivateCompanyAdmin, editUser } = this.state;
        const { usersStore } = this.props;
        return (<Form loading={usersStore.isLoading}>
            <Modal open={showDeactivateCompanyAdmin}>
                <Modal.Header>Deactivate Company Admin</Modal.Header>
                <Modal.Content>
                    <p>Are you sure you want to deactivate the company admin?  This will deactive all users for the company.</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => {
                            let user = { ...this.state.editUser };

                            user.active = true;

                            this.setState({ editUser: user, showDeactivateCompanyAdmin: false });
                        }}
                        negative
                    >
                        No
                    </Button>
                    <Button
                        loading={usersStore.isLoading}
                        onClick={async () => {
                            await this.deactivateCompanyAdmin(editUser);
                            this.setState({ showDeactivateCompanyAdmin: false, editIndex: -1 });
                        }}
                        positive
                    >
                        Yes
                    </Button>
                </Modal.Actions>
            </Modal>
            <Grid stackable columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Input
                            data-testid="new-first-name"
                            required
                            label="First Name"
                            placeholder="First Name"
                            name="firstName"
                            value={newUser.firstName}
                            onChange={this.onNewUserTextChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Input
                            data-testid="new-last-name"
                            required
                            label="Last Name"
                            placeholder="Last Name"
                            name="lastName"
                            value={newUser.lastName}
                            onChange={this.onNewUserTextChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column>
                        <Form.Input
                            data-testid="new-email"
                            required
                            label="Email"
                            placeholder="Email"
                            name="email"
                            value={newUser.email}
                            onChange={this.onNewUserTextChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field label="MFA Enabled" />
                        <Form.Radio toggle name="mfaEnabled" onChange={this.onNewUserMfaChange} />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Dropdown
                            data-testid="new-type"
                            required
                            selection
                            label="Type"
                            placeholder="Type"
                            options={this.userTypeOptions}
                            name="userType"
                            text={newUser.userType}
                            value={newUser.userType}
                            onChange={(e, d) => this.onNewUserDropdownChange(d)}
                            search
                        />
                    </Grid.Column>
                    <Grid.Column>
                        {newUser.userType !== 'Executive' && newUser.userType !== 'Company Admin' ? <Form.Dropdown
                            data-testid="new-reports-to"
                            required
                            selection
                            label="Reports To"
                            placeholder="Reports To"
                            options={this.potentialManagers(newUser)}
                            name="reportsToId"
                            value={newUser.reportsToId}
                            onChange={(e, d) => this.onNewUserDropdownChange(d)}
                            search
                        /> : null}
                        {
                            newUser.userType === 'Executive' && <>
                                <Form.Field label="Read Only Access" />
                                <Form.Radio toggle name="readOnly" onChange={this.onNewUserReadOnlyChange} value={newUser.isReadOnly} />
                            </>
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column>
                        <Form.Input
                            data-testid="new-email"
                            required
                            label="Confirm Email"
                            placeholder="Confirm Email"
                            name="confirmEmail"
                            value={newUser.confirmEmail}
                            onChange={this.onNewUserTextChange}
                            error={!!newUser.confirmEmail && newUser.confirmEmail !== newUser.email ? 'Emails are different.' : undefined}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column verticalAlign="bottom">
                        <Form.Button style={{ width: 189 }} primary icon labelPosition="right" onClick={this.saveNewUser} disabled={!this.saveButtonEnabled()}><Icon name="user plus" />Save New User</Form.Button>
                        <Button style={{ width: 189 }} onClick={this.openAllUsersModal} primary icon labelPosition="right">
                            <Icon name="user plus" />
                            Add Existing User
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Message color="blue">You are billed $25 monthly for each active user.</Message>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form >);
    }

    renderEditButtons(editIndex, user, i) {
        const { currentUser } = this.props.usersStore;

        if (!this.isAdmin(currentUser) && !this.isCompanyAdmin(currentUser) && this.isCompanyAdmin(user)) {
            return null;
        }

        if (!this.hasHigherRank(user)) {
            return null;
        }

        if (editIndex !== i) {
            return <Button primary compact data-testid={`edit-${i}`} basic size="small" icon="edit" onClick={() => this.onEditClick(i)}></Button>;
        }

        return <div>
            <Button primary compact data-testid={`save-${i}`} basic size="small" icon="save" onClick={() => this.onSaveClick(i)}></Button>
            <Button negative compact data-testid={`cancel-${i}`} basic size="small" icon="cancel" onClick={() => this.onCancelClick(i)}></Button>
        </div>;
    }

    async removeFromCompany(user) {
        const u = this.props.usersStore.companyUsers.find(u => u.userId === user.userId);
        await u.removeUserFromCompany(this.props.company.id);
        await this.props.usersStore.loadCompanyUsers(this.props.company.id);
    }

    renderTableRow(user, i, editIndex, editUser) {
        const reportsToOptions = this.potentialManagers(user);
        const reportsToName = this.userFromId(editUser.reportsToId);

        return (<Table.Row key={`user_row_${i}`} style={{ overflow: 'visible' }}>
            <Table.Cell>
                {this.renderEditButtons(editIndex, user, i)}
            </Table.Cell>
            <Table.Cell>
                {editIndex === i && this.canChangeActiveStatus(user) ?
                    <Checkbox toggle data-testid={`edit-active-${i}`} name="active" onChange={(e, d) => this.onCheckboxChanged(d, i)} checked={editUser.active} />
                    : this.formatBoolean(user.active)}
            </Table.Cell>
            <Table.Cell>{editIndex !== i ?
                user.firstName :
                <Input data-testid={`edit-first-name-${i}`} fluid name="firstName" onChange={(e) => this.onGridTextChange(e, i)} placeholder='First Name' value={editUser.firstName} />}
            </Table.Cell>
            <Table.Cell>{editIndex !== i ?
                user.lastName :
                <Input data-testid={`edit-last-name-${i}`} fluid name="lastName" onChange={(e) => this.onGridTextChange(e, i)} placeholder='Last Name' value={editUser.lastName} />}
            </Table.Cell>
            <Table.Cell>{user.email}</Table.Cell>
            <Table.Cell>{editIndex !== i ? this.formatBoolean(user.mfaEnabled) :
                <Radio toggle name="mfaEnabled" onChange={this.onEditUserMfaEnabledChange} checked={editUser.mfaEnabled} />}
            </Table.Cell>
            <Table.Cell>{editIndex === i && this.canChangeUserType(user) ?
                <Dropdown search data-testid={`edit-type-${i}`} fluid name="userType" selection options={this.userTypeOptions} onChange={(e, d) => this.onGridDropdownChange(d, i)} placeholder='Type' value={editUser.userType} text={editUser.userType} />
                : user.userType}
            </Table.Cell>
            <Table.Cell>{editIndex === i && this.canChangeReportsTo(user) ?
                <Dropdown search data-testid={`edit-report-${i}`} fluid name="reportsToId" selection options={reportsToOptions} onChange={(e, d) => this.onGridDropdownChange(d, i)} placeholder='Reports To' value={editUser.reportsToId} text={reportsToName} />
                : this.userFromId(user.reportsToId)}
            </Table.Cell>
            <Table.Cell>
                {editIndex === i && user.userType === 'Executive' ?
                    <Radio toggle name="readOnly" onChange={this.onEditUserReadOnlyChange} checked={editUser.isReadOnly} /> :
                    user.userType === 'Executive' ? this.formatBoolean(user.isReadOnly) : null}
            </Table.Cell>
            <Table.Cell>
                {user.companyIds.length > 1 ? <Button onClick={() => this.openRemoveUserModal(user)}>Remove</Button> : null}
            </Table.Cell>
            <Table.Cell>
                {!this.isCurrentUser(user) && <Button onClick={() => this.openDeleteUserModal(user)}>Delete</Button>}
            </Table.Cell>
        </Table.Row>);
    }

    renderTableRows() {
        const companyUserClones = this.props.usersStore.companyUsers.map(u => getSnapshot(u));
        const { editIndex, editUser } = this.state;

        return (<Table.Body style={{ overflow: 'visible' }}>
            {companyUserClones.map((user, i) =>
                this.renderTableRow(user, i, editIndex, editUser)
            )}
        </Table.Body>);
    }

    renderRemoveUserModal() {
        const { showRemoveUserModal, userToRemove } = this.state;
        return (
            <Modal open={showRemoveUserModal} onClose={this.closeRemoveUserModal}>
                <Modal.Header>Remove User</Modal.Header>
                <Modal.Content>
                    <p>Are you sure you want to remove {userToRemove?.firstName} from the company?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.closeRemoveUserModal} negative>No</Button>
                    <Button onClick={this.confirmRemoveUser} positive>Yes</Button>
                </Modal.Actions>
            </Modal>
        );
    }

    renderDeleteUserModal() {
        const { showDeleteUserModal, userToDelete } = this.state;
        return (
            <Modal open={showDeleteUserModal} onClose={this.closeDeleteUserModal}>
                <Modal.Header>Delete User</Modal.Header>
                <Modal.Content>
                    <p>Are you sure you want to delete {userToDelete?.firstName} from Multiply?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.closeDeleteUserModal} negative>No</Button>
                    <Button onClick={this.confirmDeleteUser} positive>Yes</Button>
                </Modal.Actions>
            </Modal>
        );
    }

    render() {
        const { usersStore } = this.props;
        const { error, showAllUsersModal } = this.state;
        return (
            <div style={componentContainerStyle}>
                <MultiplyHeader text="User Management" />
                <div style={componentContentStyle}>
                    {this.renderNewUserForm()}
                    <ErrorDisplay message={error} />
                    <Loading active={usersStore.companyUsers.some(u => u.isLoading)} >
                        <Table celled width={13} textAlign="center" striped style={{ overflow: 'visible' }}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={1} />
                                    <Table.HeaderCell width={1}>
                                        Active
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                        First Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                        Last Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                        Email
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1}>
                                        MFA Enabled
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                        Type
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                        Reports To
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1}>
                                        Read Only Access
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1}>
                                        Remove From Company
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1}>
                                        Delete From Multiply
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {this.renderTableRows()}
                        </Table>
                    </Loading>
                    <AllUsersListModal
                        usersStore={usersStore}
                        open={showAllUsersModal}
                        onClose={this.closeAllUsersModal}
                        onAdd={this.onAddFromUserModal}
                    />
                    {this.renderRemoveUserModal()}
                    {this.renderDeleteUserModal()}
                </div>
            </div>
        );
    }
}

export default observer(UserManagement);