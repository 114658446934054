import { types, flow, getSnapshot } from 'mobx-state-tree';
import { ApiStore } from './ApiStore';
import { CompanyBackground, NullCompanyBackground } from './CompanyBackground';
import { ProspectProfile } from './ProspectProfile';
import { TopCompetitor } from './TopCompetitor';
import { CompanyOffering } from './CompanyOffering';
import axios from 'axios';
import { QuestionAnswer } from './QuestionAnswer';

const _company = types.model('Company', {
  id: types.optional(types.string, ''),
  name: types.maybe(types.string),
  phone: types.maybe(types.string),
  streetAddress: types.maybe(types.string),
  zipCode: types.maybe(types.string),
  city: types.maybe(types.string),
  state: types.maybe(types.string),
  differentiators: types.optional(types.array(types.string), []),
  generalAsks: types.optional(types.array(types.string), []),
  addressableProblems: types.optional(types.array(types.string), []),
  background: types.optional(
    CompanyBackground,
    getSnapshot(NullCompanyBackground)
  ),
  prospectProfile: types.optional(
    types.union(ProspectProfile, types.array(ProspectProfile)),
    []
  ),
  topCompetitors: types.optional(types.array(TopCompetitor), []),
  offerings: types.optional(types.array(CompanyOffering), []),
  questionsAndAnswers: types.optional(types.array(QuestionAnswer), []),
});

export const Company = types.compose(_company, ApiStore).actions((self) => ({
  chargeForUser: flow(function* chargeForUser() {
    const body = { companyId: self.id };

    yield self.postApi('/users/charge', body);
  }),
  createFromSnapshot: flow(function* createFromSnapshot(snapshot) {
    delete snapshot.prospectProfile;
    yield self.postApi('/companies', snapshot, self);
  }),
  updateFromSnapshot: flow(function* updateFromSnapshot(snapshot) {
    yield self.patchApi(`/companies/${self.id}`, snapshot, self);
  }),
  updateCompanyBackgroundFromSnapshot: flow(
    function* updateCompanyBackgroundFromSnapshot(snapshot) {
      self.isLoading = true;
      const file = snapshot.uploadFile;

      if (file) {
        const headers = {
          'Content-Type': file.type,
        };
        const result = yield axios.post(
          `/companies/${self.id}/uploads/${file.name}`,
          {},
          { headers }
        );
        const uploadUrl = result.data.uploadUrl;

        yield axios.put(uploadUrl, file, { headers, baseURL: '' });
        snapshot.uploadKey = result.data.key;
        delete snapshot.uploadFile;
      }

      const body = { background: snapshot };

      yield self.patchApi(`/companies/${self.id}`, body, self);
    }
  ),
  updateDifferentiatorsFromSnapshot: flow(
    function* updateDifferentiatorsFromSnapshot(differentiatorsSnapshot) {
      const body = { differentiators: differentiatorsSnapshot };
      yield self.patchApi(`/companies/${self.id}`, body, self);
    }
  ),
  updateGeneralAsksFromSnapshot: flow(
    function* updateGeneralAsksFromSnapshot(generalAsksSnapshot) {
      const body = { generalAsks: generalAsksSnapshot };
      yield self.patchApi(`/companies/${self.id}`, body, self);
    }
  ),
  updateAddressableProblemsFromSnapshot: flow(
    function* updateAddressableProblemsFromSnapshot(addressableProblemsSnapshot) {
      const body = { addressableProblems: addressableProblemsSnapshot };
      yield self.patchApi(`/companies/${self.id}`, body, self);
    }
  ),
  updateProspectProfileFromSnapshot: flow(
    function* updateProspectProfileFromSnapshot(targetProfileSnapshot) {
      const body = { prospectProfile: targetProfileSnapshot };
      yield self.patchApi(`/companies/${self.id}`, body, self);
    }
  ),
  updateTopCompetitorsFromSnapshot: flow(
    function* updateTopCompetitorsFromSnapshot(topCompetitorsSnapshot) {
      const body = { topCompetitors: topCompetitorsSnapshot };
      yield self.patchApi(`/companies/${self.id}`, body, self);
    }
  ),
  updateOfferingsFromSnapshot: flow(function* updateOfferingsFromSnapshot(
    offeringsSnapshot
  ) {
    self.isLoading = true;
    try {
      let index = 0;
      for (let snapshot of offeringsSnapshot) {
        const file = snapshot.uploadFile;

        if (snapshot.uploadFile) {
          const headers = {
            'Content-Type': file.type,
          };
          const result = yield axios.post(
            `/companies/${self.id}/uploads/${file.name}`,
            {},
            { headers }
          );
          const uploadUrl = result.data.uploadUrl;

          yield axios.put(uploadUrl, file, { headers, baseURL: '' });
          snapshot.uploadKey = result.data.key;
          delete snapshot.uploadFile;
        } else if (
          self.offerings.length - 1 >= index &&
          self.offerings[index].uploadKey &&
          !snapshot.uploadKey
        ) {
          const key = self.offerings[index].uploadKey.split('/')[1];
          yield axios.delete(`/companies/${self.id}/uploads/${key}`);
        }
        index++;
      }

      const body = { offerings: offeringsSnapshot };
      yield self.patchApi(`/companies/${self.id}`, body, self);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      self.isLoading = false;
    }
  }),
  updateQuestionsAndAnswers: flow(function* updateQuestionsAndAnswers(
    quesionAnswerSnapshot
  ) {
    self.isLoading = true;
    try {
      let index = 0;
      for (let snapshot of quesionAnswerSnapshot) {
        const file = snapshot.uploadFile;

        if (snapshot.uploadFile) {
          const headers = {
            'Content-Type': file.type,
          };
          const result = yield axios.post(
            `/companies/${self.id}/uploads/${file.name}`,
            {},
            { headers }
          );
          const uploadUrl = result.data.uploadUrl;

          yield axios.put(uploadUrl, file, { headers, baseURL: '' });
          snapshot.uploadKey = result.data.key;
          delete snapshot.uploadFile;
        } else if (
          self.questionsAndAnswers.length - 1 >= index &&
          self.questionsAndAnswers[index].uploadKey &&
          !snapshot.uploadKey
        ) {
          const key = self.questionsAndAnswers[index].uploadKey.split('/')[1];
          yield axios.delete(`/companies/${self.id}/uploads/${key}`);
        }
        index++;
      }

      const body = { questionsAndAnswers: quesionAnswerSnapshot };
      yield self.patchApi(`/companies/${self.id}`, body, self);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      self.isLoading = false;
    }
  }),
}));

export const NullCompany = Company.create({
  name: '',
  phone: '',
  streetAddress: '',
  zipCode: '',
  city: '',
  state: '',
});
