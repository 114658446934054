import { observer } from 'mobx-react';
import React from 'react';

export const VideoPreview = observer(({
    recordingType, videoUrl
}) => {
    return (
        <>
            <div className="timmer component" style={{ padding: videoUrl ? '15px' : '7px' }}>
                <p>
                    <h5>Preview</h5>
                </p>
            </div>
            <video
                className={`video after-recording ${recordingType === 'casual_encounter' ? 'casual-after-encounterVideo' : ''}`}
                controls
                autoPlay
            >
                <source src={videoUrl} type="video/mp4" />
            </video>
        </>
    );
});