import React from 'react';
import { observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import { useEffect, useState } from 'react';
import NewAssessmentDetails from '../components/PerformanceReviews/NewAssessmentDetails';
import { Input, Grid, Icon } from 'semantic-ui-react';
import {
    componentContainerStyle,
} from '../constants';
import MultiplyHeader from '../components/MultiplyHeader/MultiplyHeader';
import { AssessmentUserViewModel } from '../models/AssessmentUserViewModel';
import {
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody,
    Button,
    Table,
    Card,
    CardContent,
    CardHeader,
} from 'semantic-ui-react';

const NewAssessmentsReportPage = ({ usersStore, assessmentsStore, companyStore }) => {
    const [managersMap, setManagersMap] = useState({});
    const [salesPersonFilter, setSalesPersonFilter] = useState('');
    const [managerFilter, setManagerFilter] = useState('');
    const [list, setList] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);

    useEffect(() => {
        let map = usersStore.salesManagers.reduce((acc, curr) => {
            acc[curr.userId] = curr;
            return acc;
        }, {});

        setManagersMap(map);

    }, [usersStore.salesManagers]);

    useEffect(() => {
        const currentUser = usersStore.currentUser;
        let workingList = currentUser.isExecutive ? usersStore.companyUsers : usersStore.getDirectReports(currentUser.userId);

        if (salesPersonFilter) {
            workingList = workingList.filter(user => user.fullName.toLowerCase().indexOf(salesPersonFilter.toLowerCase()) > -1);
        }
        workingList = workingList.map(user => AssessmentUserViewModel.create({ ...getSnapshot(user), reportsToName: managersMap[user.reportsToId]?.fullName ?? '' }));

        if (managerFilter) {
            workingList = workingList.filter(user => user.reportsToName.toLowerCase().indexOf(managerFilter.toLowerCase()) > -1);
        }

        setList(workingList);
    }, [managersMap, salesPersonFilter, usersStore.companyUsers, managerFilter, usersStore]);

    const containerStyle = {
        ...componentContainerStyle,
        paddingLeft: '40px',
        paddingRight: '40px',
    };

    return <div style={containerStyle}>
        <MultiplyHeader text='Performance Reports'></MultiplyHeader>
        <Grid columns={2} stretched>
            <Grid.Row>
                <Grid.Column>
                    <Input
                        fluid
                        icon={<Icon name='search' />}
                        placeholder='Search Salesperson...'
                        onChange={(e) => setSalesPersonFilter(e.target.value)}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Input
                        fluid
                        icon={<Icon name='search' />}
                        placeholder='Search Manager...'
                        onChange={(e) => setManagerFilter(e.target.value)}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
        <Card fluid>
            <CardContent>
                <CardHeader>Assessments</CardHeader>
            </CardContent>
            <CardContent style={{ padding: 0 }}>
                <Table style={{ border: 'none' }}>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Salesperson</TableHeaderCell>
                            <TableHeaderCell>Role</TableHeaderCell>
                            <TableHeaderCell>Latest Assessment</TableHeaderCell>
                            <TableHeaderCell>Latest Grade</TableHeaderCell>
                            <TableHeaderCell>Reports To</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {list.map((user) => {
                            const assessments = assessmentsStore.assessmentsGroupedById[user.userId] ?? [];
                            const latestAssessment = assessments.length > 0 ? assessments[0] : undefined;
                            return (
                                <React.Fragment key={user.userId}>
                                    <Table.Row
                                        onClick={() => setSelectedUserId(selectedUserId === user.userId ? null : user.userId)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <TableCell>{user.fullName}</TableCell>
                                        <TableCell>{user.userType}</TableCell>
                                        <TableCell>{latestAssessment?.completedDateTime ? new Date(latestAssessment.completedDateTime).toLocaleDateString() : ''}</TableCell>
                                        <TableCell><Button
                                            data-testid='rating'
                                            color={latestAssessment?.grade ? latestAssessment.grade : 'grey'}
                                            icon
                                            size='massive'
                                            circular
                                            style={{ marginLeft: '19px' }}
                                        /></TableCell>
                                        <TableCell>{user.reportsToName}</TableCell>
                                    </Table.Row>
                                    {selectedUserId === user.userId && (
                                        <Table.Row>
                                            <Table.Cell colSpan="5" style={{ padding: 0 }}>
                                                <NewAssessmentDetails
                                                    assessments={assessments}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    </div>;


};

export default observer(NewAssessmentsReportPage);