import React from 'react';
import { observer } from 'mobx-react';
import NewAssessmentDetails from '../components/PerformanceReviews/NewAssessmentDetails';
import {
    componentContainerStyle,
} from '../constants';
import MultiplyHeader from '../components/MultiplyHeader/MultiplyHeader';
import {
    Card,
    CardContent,
    CardHeader,
} from 'semantic-ui-react';

const MyAssessmentsReportPage = ({ assessmentsStore, usersStore }) => {
    const containerStyle = {
        ...componentContainerStyle,
        paddingLeft: '40px',
        paddingRight: '40px',
    };

    return <div style={containerStyle}>
        <MultiplyHeader text='My Performance Reports'></MultiplyHeader>
        <Card fluid>
            <CardContent>
                <CardHeader>Assessments</CardHeader>
            </CardContent>
            <CardContent style={{ padding: 0 }}>
                <NewAssessmentDetails
                    assessments={assessmentsStore.closedAssessmentsForUser(usersStore.currentUser.userId)}
                />
            </CardContent>
        </Card>
    </div>;


};

export default observer(MyAssessmentsReportPage);