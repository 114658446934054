import React, { useState, useEffect } from 'react';
import './App.css';
import { CreateRootStore } from './models/RootStore';
import { CurrentUserInitializer } from './CurrentUserInitializer';
import Splash from './components/Splash/Splash';
import Home from './pages/Home';
import { Route, Switch, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import ErrorDisplay from './components/ErrorDisplay/ErrorDisplay';
import { CompanyInitializer } from './CompanyInitializer';
import { useUserContext } from './UserContextProvider';
import { useAuth0 } from '@auth0/auth0-react';
import LoginView from './Login';
import * as Sentry from '@sentry/react';

const App = observer(() => {
  const { isAuthenticated, user } = useAuth0();

  if (!isAuthenticated) {
    return <LoginView />;
  }

  Sentry.setUser({ id: user?.sub?.split('|')[1], email: user?.email });


  return <AppInternal />;
});

const AppInternal = () => {
  const [rootStore] = useState(CreateRootStore());
  const { userId } = useUserContext();
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState('');
  const { logout } = useAuth0();

  useEffect(() => {
    const initialize = async () => {
      try {
        const {
          usersStore,
          companyStore,
          assessmentsStore,
          adminStore,
          modulesStore,
        } = rootStore;

        await CurrentUserInitializer(
          usersStore,
          adminStore,
          userId
        );

        let companyId = usersStore.currentUser.companyIds[0];

        await CompanyInitializer(
          companyStore,
          assessmentsStore,
          usersStore,
          modulesStore,
          companyId
        );

        setIsLoaded(true);
      } catch (error) {
        console.error(error);
        logout();
      }
    };

    initialize();
  }, [logout, rootStore, setError, setIsLoaded, userId]);

  if (!isLoaded) {
    return <div data-testid='splash'>
      <ErrorDisplay message={error} />
      <Splash showLoader={true} />
    </div>;
  }

  return (
    <div style={{ height: '100%' }} data-testid='app'>
      <Switch>
        <Route
          path='/'
          render={(props) => <Home {...props} rootStore={rootStore} />}
        />
      </Switch>
    </div>
  );
};

export default App;
