import React, { useEffect, useState } from 'react';
import { Button, Modal, Grid, Header, Icon } from 'semantic-ui-react';
import PreviousAssessmentList from './PreviousAssessmentList';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import 'github-markdown-css/github-markdown-light.css';
import MurphyTag from '../Murphy/MurphyTag';

const PreviousAssessmentDisplay = (props) => {
    const { assessmentHistory, label } = props;

    const [showAssessment, setShowAssessment] = useState(false);
    const [selectedAssessment, setSelectedAssessment] = useState(assessmentHistory?.lastAssessment);

    useEffect(() => {
        setSelectedAssessment(assessmentHistory?.lastAssessment);
    }, [assessmentHistory]);

    if (!selectedAssessment) {
        return null;
    }

    const renderPreviousAssessment = () => {
        if (selectedAssessment.subject === 'Company Overview') {
            return <Grid columns={2}>
                <Grid.Column>
                    <Header size='small'>Positives</Header>
                    <ReactMarkdown remarkPlugins={[remarkGfm]} className="markdown-body">
                        {selectedAssessment.companyOverviewPositivesView}
                    </ReactMarkdown>
                </Grid.Column>
                <Grid.Column>
                    <Header size='small'>Areas for Improvement </Header>
                    <ReactMarkdown remarkPlugins={[remarkGfm]} className="markdown-body">
                        {selectedAssessment.companyOverviewAreasForImprovementView}
                    </ReactMarkdown>
                </Grid.Column>
            </Grid>;
        } else {
            return <Grid columns={2}>
                <Grid.Column>
                    <Header size='small'>Positives</Header>
                    <ReactMarkdown remarkPlugins={[remarkGfm]} children={selectedAssessment.offeringPositives} className="markdown-body" />
                </Grid.Column>
                <Grid.Column>
                    <Header size='small'>Areas for Improvement </Header>
                    <ReactMarkdown remarkPlugins={[remarkGfm]} children={selectedAssessment.offeringAreasForImprovement} className="markdown-body" />
                </Grid.Column>
            </Grid>;
        }
    };

    const completedDateTime = new Date(selectedAssessment.completedDateTime).toLocaleDateString();

    return <>
        {showAssessment ? <Modal open={showAssessment}>
            <Modal.Header>
                Previous {selectedAssessment.sessionType} Feedback on {completedDateTime}
                <Button
                    data-testid="assessment-grade"
                    color={selectedAssessment.colorForGrade}
                    icon
                    circular
                    style={{ marginLeft: 10, position: 'relative', top: -8 }} />
                <Button color='red' icon='close' onClick={() => setShowAssessment(false)} floated='right'></Button>
            </Modal.Header>
            <Modal.Content>
                {selectedAssessment.aiGenerated && <MurphyTag />}
                {renderPreviousAssessment()}
                <PreviousAssessmentList assessmentHistory={assessmentHistory} onAssessmentButtonClick={setSelectedAssessment} />
            </Modal.Content>
        </Modal> :
            <Button primary onClick={() => setShowAssessment(true)} style={{ marginTop: 20 }}>{label || 'Show Previous Feedback'}</Button>}
    </>;
};

export default PreviousAssessmentDisplay;