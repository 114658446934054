import 'mobx-react-lite/batchingForReactDom';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-less/semantic.less';
import AppWithAuth from './AppWithAuth';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://3fccc379377744aa21394c8cb426f067@o4508423217479680.ingest.us.sentry.io/4508423219052544',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_ENVIRONMENT,
});


ReactDOM.render(
  <AppWithAuth />,
  document.getElementById('root')
);

